<template>
    <div class="BigBox">
    <canvas id="myCanvas"></canvas>
      <!-- 头部导航 -->
      <div class="NavBox">
        <div class="Home" @click="BackHome"><img src="../../assets/ConstructionLandscape/Home.png" alt=""></div>
        <div class="Title">{{DataList.title}}</div>
        <div class="GeRen" @click="GeRen"><img src="../../assets/ConstructionLandscape/GeRen.png" alt=""></div>
      </div>
      <!-- 当前位置 -->
      <div class="DangQianDiZhi" @click="BackConstruction">
        <img style="width: 3vh;height: 3vh;cursor: pointer;" src="../../assets/ConstructionLandscape/ConstructionXiang/BackImg.png" alt="">
        <div style="cursor: pointer;margin-left: 1vh;" >建筑景观馆大厅</div>
      </div>
      <!-- 内容 -->
      <div class="ConBox">
        <div class="LeftCon">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(imgs, index) in DataList.imgs">
                        <img :src=imgs.resourceUrl style="width: 100%;height: 100%;" alt="">
                    </div>
                </div>
            </div>
            <!-- <div class="LeftConZhuTu"><img style="height:100%;width:100%" :src=ZhuTu alt=""></div>
            <div class="LeftConItem">
                <div v-for="(imgs, index) in DataList.imgs" :key="index" class="JianZhuItemImg" @click="TabImg(index)">
                    <div style="flex-shrink: 0;height: 85%;width: 25vh;"><img style="height:100%;width:100%"  :src=imgs.resourceUrl alt=""></div>
                    <div style="width: 100%;height: 15%; display: flex;justify-content: center;align-items: center;color: white;">{{ imgs.title }}</div>
                </div>
            </div> -->
        </div>
        <div class="RightCon">
            <div class="RightTop">
                <div class="RightTopLeft">
                    <div style="width:95%;height: 92%;">
                        <div style="width:95%;height: 92%;overflow: scroll;">
                            <div style="display:flex;align-items: center;">
                                <img style="height:8%" src="../../assets/ConstructionLandscape/ConstructionXiang/TitleImg.png" alt="">
                                <div style="font-size: 3vh;margin-left: 2vh;margin-bottom: 0.5vh;">建筑详情</div>
                            </div>
                            <div style="margin:0.5vh 0">中文名：{{DataList.title}}</div>
                            <div style="margin:0.5vh 0">外文名：{{DataList.englishName}}</div>
                            <div style="margin:0.5vh 0">地理位置：{{DataList.provinceName}}{{DataList.cityName}}</div>
                            <div style="margin:0.5vh 0">占地面积：{{DataList.areaCovered}}</div>
                            <div style="margin:0.5vh 0">性质：{{DataList.buildingType}}</div>
                            <div style="margin:0.5vh 0">建造者：{{DataList.builder}}</div>
                            <div style="margin:0.5vh 0">建造年代：{{DataList.buildTime}}</div>
                        </div>
                    </div>
                </div>
                <div class="RightTopRight">
                    <div style="width:95%;height: 92%;">
                        <div style="display:flex;align-items: center;">
                            <img style="height:8%" src="../../assets/ConstructionLandscape/ConstructionXiang/TitleImg.png" alt="">
                            <div style="font-size: 3vh;margin-left: 2vh;">建筑视频</div>
                        </div>
                        <div style="width:90%;height: 80%;margin: 2vh auto;background-color: black;">
                            <video ref="myVideo" style="width:100%;height:100%" controls="controls" :src="videos"  @play="HandleFullScreenOne" @ended="HandleExitFullScreenOne"></video>
                        </div>
                    </div>
                </div>
            </div>
            <div class="RightButtom">
                <div style="height:90%;width:95%;">
                    <div style="display:flex;align-items: center;">
                        <img style="height:8%" src="../../assets/ConstructionLandscape/ConstructionXiang/TitleImg.png" alt="">
                        <div style="font-size: 3vh;margin-left: 2vh;">建筑历史</div>
                    </div>
                    <div style="width:100%;height:85%;margin:2vh 0;overflow: scroll;text-indent:2em;line-height: 3vh;">
                        {{ DataList.summary }}
                    </div>
                </div>
            </div>
        </div>
      </div>
      <!-- 弹框放大图片 -->
      <div v-if="BigImgShow" @click="BigImgShow = false" style="cursor: pointer; width: 100%;height: 100%;position: fixed;left: 0;top: 0;background-color: rgba(0,0,0,0.5);backdrop-filter: blur(0.225rem);display: flex;justify-content: center;align-items: center;">
        <img :src="BigImg" style="height: 80%;width: auto;" alt="">  
      </div>
    </div>
  </template>
  
  <script>
  import $ from "jquery";
  
  export default {
      data(){
          return{
              DataList:'',
              ZhuTu:'',
              videos:'',
              BigImg:'',
              BigImgShow:false
          }
      },
      mounted(){
        this.GetDataList()
        this.XingKong()
        this.initSwiper()
        // 退出全屏暂停播放
        document.addEventListener('fullscreenchange', this.handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', this.handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', this.handleFullscreenChange);
      },
      methods:{
        initSwiper(){
            setTimeout(() => {
                var swiper = new Swiper(".swiper-container", {
                    // 设置Slide的切换效果，默认为"slide"（普通位移切换），还可设置为"fade"（淡入）、"cube"（方块）、"coverflow"（3d流）、"flip"（3d翻转）、"cards"(卡片式)、"creative"（创意性）。
                    effect: 'cards',
                    loop: true,
                    // 该选项给Swiper用户提供小小的贴心应用，设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状。（根据浏览器形状有所不同）
                    grabCursor: true,
                    shadow: true,
                    // 阴影距离
                    shadowOffset: 200,
                    // 鼠标滚轮滑动
                    mousewheelControl:true,
                   
                  
                });
                // 监听点击事件
                swiper.on('click',(e)=>{
                    this.BigImgShow = true
                    this.BigImg = this.DataList.imgs[e.activeIndex].resourceUrl
                    // console.log(this.DataList.imgs[e.activeIndex]);
                })
            }, 300)
        },
        //星空特效
        XingKong() {
        var myCanvas = document.getElementById('myCanvas');
        var ctx = myCanvas.getContext("2d");
        var starlist = [];
        function init() {
            // 设置canvas区域的范围为整个页面
            myCanvas.width = window.innerWidth;
            myCanvas.height = window.innerHeight;
        };
        init();
        // 监听屏幕大小改变 重新为canvas大小赋值
        // window.onresize = init;

        var list = [];
        // 将页面上每一个小圆点的信息存在list数组中
        // 数组中的每一位是一个对象  对象中存着每一个小点的信息
        // 利用构造函数生成数组

        function Star(x, y, radius, disX, disY) {
            // 将传入的参数设置为对象的属性
            // 位置坐标
            this.x = x;
            this.y = y;
            // 半径
            this.radius = radius;
            // 变化距离
            this.disX = disX;
            this.disY = disY;
        }

        for (var i = 0; i < 200; i++) {
            // 设置位随机值
            var x = Math.random() * myCanvas.width;
            var y = Math.random() * myCanvas.height;
            var radius = Math.random() * 2;
            var disX = x - myCanvas.width / 2;
            var disY = y - myCanvas.height / 2;
            // 每一个的信息存在对象中  所有小点存在list数组中
            list.push(new Star(x, y, radius, disX, disY));
        }

        // 绘制并且运动函数
        function animate() {
            // 在每次渲染前清除一下屏幕
            ctx.clearRect(0, 0, myCanvas.width, myCanvas.height);
            // 下一次渲染  根据存放在数组中的信息进行画小星星
            for (var i = 0; i < 200; i++) {
            // 取出每一个信息点信息
            var a = list[i];

            // a的坐标在改变  根据disx disy进行改变  /50改变值设置的小一点
            a.x += a.disX / 30;
            a.y += a.disY / 30;

            // 如果小圆点运动到边界之外
            if (a.x < 0 || a.y < 0 || a.x > myCanvas.width || a.y > myCanvas.height) {
                // 重新出现一个小星星的位置
                a.x = Math.random() * myCanvas.width;
                a.y = Math.random() * myCanvas.height;
                // 同时根据 当前新出现的位置设置移动距离
                a.disX = a.x - myCanvas.width / 2;
                a.disY = a.y - myCanvas.height / 2;
            }

            // 开始画每一个点
            ctx.beginPath();
            // 设置填充颜色
            ctx.fillStyle = "#00F5FF";
            // 每一小星星为一个小圆
            ctx.arc(a.x, a.y, a.radius, 0, Math.PI * 2, false);
            // 进行填充
            ctx.fill();
            }
            // 不断绘制canvas  根据disx与disy的位置变化  更新坐标  视觉上位运动状态
            setTimeout(animate, 70);
        }
        animate();
        },
        // 退出全屏暂停播放
        handleFullscreenChange() {
            const video = this.$refs.myVideo;
            if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
                video.pause(); // 当视频退出全屏时暂停播放
            }
        },
        // 切换主图
        TabImg(index){
            this.ZhuTu = this.DataList.imgs[index].resourceUrl
        },
        // 返回上一页
        BackHome(){
            this.$router.push('/');
            // window.location.reload();
        },
        // 返回博物館首頁
        BackConstruction(){
            this.$router.push('/ConstructionLandscape');
        },
        // 个人中心
        GeRen(){
            // 进入页面就判断是否有token  如果没有那就继续登陆 如果有就进入个人中心
            if(this.$cookies.get("token")==null){
                this.$router.push('/login')
            }else{
                this.$router.push('/personalCenter')
            }
        },
        //   获取数据
        GetDataList(){
            var axios = require("axios");
                var config = {
                method: 'get',
                url:  this.$Schttp+'/vtp/app/building/detail/'+this.$route.query.ConstructionLandscapeID +'',
                headers: { }
            };
            axios(config).then((res) => {
                this.DataList = res.data.data
                this.ZhuTu = this.DataList.imgs[0].resourceUrl
                this.videos = this.DataList.videos[0].resourceUrl
            })
            .catch(function (error) {
            });
        },
        //点击视频全屏播放
        HandleFullScreenOne(e) {
            this.launchFullscreen(e.target);
        },
        // 进入全屏
        launchFullscreen(element) {
            //此方法不可以在異步任務中執行，否則火狐無法全屏
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            } else if (element.oRequestFullscreen) {
                element.oRequestFullscreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullScreen();
            } else {
                var docHtml = document.documentElement;
                var docBody = document.body;
                var videobox = element;
                var cssText = "width:100%;height:100%;overflow:hidden;";
                docHtml.style.cssText = cssText;
                docBody.style.cssText = cssText;
                videobox.style.cssText = cssText + ";" + "margin:0px;padding:0px;";
                document.IsFullScreen = true;
            }
        },
        HandleExitFullScreenOne(e) {
            this.exitFullscreen(e.target);
        },
        exitFullscreen(element) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.oRequestFullscreen) {
                document.oCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else {
                var docHtml = document.documentElement;
                var docBody = document.body;
                var videobox = element;
                docHtml.style.cssText = "";
                docBody.style.cssText = "";
                videobox.style.cssText = "";
                document.IsFullScreen = false;
            }
        },
      },
      beforeUnmount() {
        // 销毁监听视频
        document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
        document.removeEventListener('mozfullscreenchange', this.handleFullscreenChange);
        document.removeEventListener('webkitfullscreenchange', this.handleFullscreenChange);
      }
      
  }
  </script>
  
  <style lang="less" scoped>
  *{
      padding: 0;
      margin: 0;
  }
  #myCanvas {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    pointer-events: none;
  }
  .BigBox{
    width: 100%;
    height: 100%;
    background-color: rgb(16,21,43);
    position: fixed;
    top: 0;
    left: 0;
    .NavBox{
        width: 100%;
        height: 10%;
        background-image: url('../../assets/ConstructionLandscape/ConstructionXiang/NavBoxBG.png');
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        font-size: 5vh;
        color: white;
        z-index: 20;
        .Home{
            height: 90%;
            margin-left: 3vh;
            margin-top: 1vh;
            cursor: pointer;
            img{
                height: 90%;
            }
        }
        .Title{
            margin-top: 1vh;
            text-shadow: 0 0 10px rgb(38, 214, 237),0 0 20px rgb(38, 214, 237),0 0 30px rgb(38, 214, 237),0 0 40px rgb(38, 214, 237);
        }
        .GeRen{
            height: 90%;
            margin-right: 3vh;
            margin-top: 1vh;
            cursor: pointer;
            img{
                height: 90%;
            }
        }
    }
    .DangQianDiZhi{
        position: absolute;
        top: 8vh;
        left: 13vh;
        color:white;
        font-size:2vh;
        display:flex
    }
    .ConBox{
        width: 98%;
        height: 85%;
        margin: 2vh auto;
        display: flex;
        justify-content: space-between;
        .LeftCon{
            width: 37%;
            height: 100%;
            background-image: url('../../assets/ConstructionLandscape/ConstructionXiang/LeftConBG.png');
            background-size: 100% 100%;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            .swiper-container{
                width: 70%;
                height: 100%;
                position: relative;
                // left: 7rem;
                .swiper-wrapper{
                    // width: 0%;
                    height: 100%;
                    align-items: center;
                    // border: 1px solid red;
                    .swiper-slide{
                        // width: 20rem;
                        height: 50%;
                        // border: 1px solid red;
                    }
                }
            }
            .LeftConZhuTu{
                width: 96%;
                margin: 1vh auto;
                height: 65%;
            }
            .LeftConItem{
                width: 96%;
                margin: 5vh auto;
                height: 25%;
                display: flex;
                overflow-x: scroll;
                .JianZhuItemImg{
                    width: 25vh;
                    height: 95%;
                    margin-right: 1vh;
                    cursor: pointer;
                }
            }
        }
        .RightCon{
            width: 62%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: white;
            font-size:2vh;
            .RightTop{
                width: 100%;
                height: 39%;
                display: flex;
                justify-content: space-between;
                .RightTopLeft{
                    width: 51.5%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-image: url('../../assets/ConstructionLandscape/ConstructionXiang/RightTopBG.png');
                    background-size: 100% 100%;
                }
                .RightTopRight{
                    width: 48%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-image: url('../../assets/ConstructionLandscape/ConstructionXiang/RightTopBG.png');
                    background-size: 100% 100%;
                }
            }
            .RightButtom{
                width: 100%;
                height: 59%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-image: url('../../assets/ConstructionLandscape/ConstructionXiang/RightBottomBG.png');
                background-size: 100% 100%;
            }
        }
    }
     // 滚动条设置
  @remvw: 1920 /100vw !important;

::-webkit-scrollbar {
  width: 7 / @remvw !important;
  /*对垂直流动条有效*/
  height: 7 / @remvw !important;
  /*对水平流动条有效*/
}

/*定义外层滚动槽*/
::-webkit-scrollbar-track-piece {
  border-radius: 6 / @remvw !important;
}

/*定义内层 滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 6 / @remvw !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8b8b8b !important;
  opacity: 0.3 !important;
}

/*定义两端按钮的样式*/
::-webkit-scrollbar-button {
  display: none !important;
}

/*定义边角样式*/
::-webkit-scrollbar-corner {
  display: none !important;
}

/*定义边角样式*/
::-webkit-scrollbar-corner {
  display: none;
}
::-webkit-scrollbar-thumb {
border-radius: 8px;
background-color: rgba(155, 155, 155, 0.5);
}
  }
  </style>